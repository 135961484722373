import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
}
export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1280.000000 1280.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">



<path d="M7389 10305 c-3 -2 -44 -9 -91 -14 -47 -5 -110 -17 -139 -26 -128
-38 -226 -70 -254 -81 -102 -41 -162 -69 -213 -98 -122 -69 -91 -66 -766 -66
-358 0 -628 -5 -653 -10 -72 -17 -129 -64 -183 -155 -24 -39 -25 -46 -30 -332
l-5 -291 -25 -28 c-14 -15 -55 -59 -91 -98 -158 -169 -352 -409 -482 -596
-160 -232 -331 -534 -427 -755 -12 -27 -28 -63 -36 -80 -8 -16 -18 -43 -23
-60 -5 -16 -21 -61 -36 -100 -28 -76 -57 -174 -80 -270 -7 -33 -18 -72 -24
-86 -17 -47 -46 -322 -44 -429 4 -251 54 -430 170 -613 104 -163 301 -304 493
-354 30 -8 73 -19 95 -24 77 -20 625 -10 625 11 0 4 -93 10 -207 13 -183 5
-218 8 -288 30 -227 70 -385 213 -471 424 -13 30 -26 74 -29 96 -4 23 -13 61
-21 85 -17 52 -19 308 -3 365 6 21 16 73 24 115 15 88 54 242 65 257 4 6 10
24 14 40 8 35 48 138 70 180 9 17 16 35 16 40 0 14 117 244 134 265 7 8 24 38
38 66 23 46 153 251 168 264 3 3 18 25 34 50 32 49 208 271 265 334 20 22 110
114 201 205 l165 166 5 400 c5 360 7 404 24 440 21 48 58 88 106 118 l35 22
720 0 c688 0 722 -1 750 -19 51 -32 85 -69 108 -116 22 -45 22 -45 25 -838 2
-710 4 -793 18 -799 9 -3 19 -1 23 5 3 6 39 47 80 91 40 45 98 111 128 148
114 139 323 427 323 446 0 3 17 32 38 64 52 81 56 87 106 188 86 176 141 314
168 420 6 22 19 65 28 95 10 30 26 120 36 200 16 138 16 151 -1 255 -22 142
-58 219 -141 302 -75 76 -145 109 -269 129 -86 13 -256 19 -266 9z m-838 -412
c7 -18 6 -30 -1 -37 -7 -7 -109 -11 -315 -11 -329 0 -339 2 -317 50 l12 25
305 0 305 0 11 -27z"/>
<path d="M5854 9037 c-2 -7 -3 -87 -2 -177 l3 -165 147 -3 148 -3 2 -537 3
-537 178 -3 177 -2 0 540 0 540 160 0 160 0 0 180 0 180 -485 0 c-387 0 -487
-3 -491 -13z m934 -164 l3 -133 -161 0 -160 0 0 -545 0 -545 -135 0 -135 0 -2
543 -3 542 -157 3 -158 3 0 134 0 135 453 -2 452 -3 3 -132z"/>
<path d="M3460 5391 c0 -69 0 -70 28 -73 l27 -3 6 -200 c7 -247 17 -284 99
-366 69 -69 134 -92 255 -91 158 0 253 72 296 222 7 25 15 130 18 240 l6 195
28 3 c27 3 27 4 27 73 l0 69 -165 0 -165 0 0 -75 0 -75 30 0 30 0 0 -155 c0
-245 -22 -305 -115 -305 -93 0 -117 66 -113 310 l3 155 28 3 c27 3 27 4 27 73
l0 69 -175 0 -175 0 0 -69z"/>
<path d="M4290 5385 l0 -75 35 0 35 0 0 -245 0 -245 -35 0 -35 0 0 -75 0 -75
335 0 335 0 0 130 0 131 -92 -3 -93 -3 -3 -52 -3 -53 -90 0 -90 0 3 93 3 92
103 3 103 3 -3 72 -3 72 -100 0 -100 0 -3 78 -3 77 91 0 90 0 0 -50 0 -50 95
0 95 0 0 125 0 125 -335 0 -335 0 0 -75z"/>
<path d="M5020 5386 l0 -76 35 0 35 0 0 -245 0 -245 -35 0 -35 0 0 -75 0 -75
193 0 c261 0 363 23 451 101 80 70 120 168 120 299 0 102 -19 165 -72 234 -46
61 -100 100 -177 128 -54 20 -84 22 -287 26 l-228 4 0 -76z m428 -104 c65 -47
97 -177 73 -294 -21 -98 -75 -156 -155 -165 l-46 -6 0 248 0 248 49 -5 c27 -3
62 -14 79 -26z"/>
<path d="M5990 5385 c0 -68 2 -75 20 -75 11 0 20 -3 20 -7 0 -5 -33 -114 -73
-243 l-72 -235 -38 -3 -37 -3 0 -75 0 -74 175 0 175 0 0 75 0 75 -40 0 c-44 0
-45 2 -29 49 l11 31 113 0 113 0 11 -31 c16 -47 15 -49 -39 -49 l-50 0 0 -75
0 -75 225 0 225 0 0 74 0 75 -41 3 -41 3 -55 175 c-29 96 -74 239 -99 318
l-45 142 -214 0 -215 0 0 -75z m300 -352 c0 -2 -34 -3 -76 -3 -57 0 -75 3 -71
13 2 6 16 53 31 102 15 50 30 99 33 110 5 14 18 -17 45 -100 21 -66 38 -121
38 -122z"/>
<path d="M6720 5315 l0 -145 80 0 80 0 0 70 0 70 50 0 50 0 0 -245 0 -245 -45
0 -45 0 0 -75 0 -75 205 0 205 0 0 75 0 75 -45 0 -45 0 0 245 0 245 50 0 50 0
0 -70 0 -70 80 0 80 0 0 145 0 145 -375 0 -375 0 0 -145z"/>
<path d="M7680 5385 c0 -68 2 -75 20 -75 11 0 20 -3 20 -7 0 -5 -33 -114 -73
-243 l-72 -235 -37 -3 -38 -3 0 -75 0 -74 175 0 175 0 0 75 0 75 -40 0 c-44 0
-45 2 -29 49 l11 31 113 0 c63 0 116 -3 118 -7 3 -5 8 -23 12 -40 l7 -33 -51
0 -51 0 0 -75 0 -75 225 0 225 0 0 75 0 75 -40 0 -39 0 -45 143 c-25 78 -69
221 -99 317 l-55 175 -216 3 -216 2 0 -75z m296 -337 c5 -16 -3 -18 -65 -18
-58 0 -71 3 -71 16 0 9 15 64 32 122 l33 106 32 -105 c19 -57 36 -112 39 -121z"/>
<path d="M8860 5385 c0 -68 2 -75 20 -75 11 0 20 -6 19 -12 0 -7 -33 -116 -72
-243 l-72 -230 -37 -3 -38 -3 0 -75 0 -74 175 0 175 0 0 75 0 75 -40 0 c-44 0
-45 2 -29 49 l11 31 113 0 c63 0 116 -3 118 -7 3 -5 8 -23 12 -40 l7 -33 -51
0 -51 0 0 -75 0 -75 225 0 225 0 0 75 0 75 -40 0 -39 0 -40 128 c-22 70 -67
213 -99 317 l-59 190 -216 3 -217 2 0 -75z m260 -221 c17 -54 33 -107 36 -116
5 -16 -3 -18 -65 -18 -58 0 -71 3 -71 16 0 19 62 225 66 221 2 -2 17 -48 34
-103z"/>
<path d="M9600 5386 l0 -76 30 0 30 0 0 -245 0 -245 -30 0 -30 0 0 -75 0 -75
180 0 180 0 0 75 0 75 -35 0 -36 0 3 88 3 87 100 6 c143 8 225 51 261 136 20
47 17 150 -5 198 -22 49 -43 68 -106 97 -47 21 -65 23 -297 26 l-248 4 0 -76z
m425 -101 c25 -24 34 -81 19 -120 -10 -25 -58 -45 -109 -45 l-45 0 0 95 0 95
55 0 c46 0 60 -4 80 -25z"/>
<path d="M10310 5385 l0 -75 35 0 35 0 0 -245 0 -245 -35 0 -35 0 0 -75 0 -75
185 0 185 0 0 74 0 75 -32 3 -33 3 -3 243 -2 242 35 0 35 0 0 75 0 75 -185 0
-185 0 0 -75z"/>
<path d="M2161 5083 c4 -203 7 -376 8 -385 1 -16 14 -18 131 -18 l130 0 0 70
0 70 -45 0 -45 0 0 250 0 250 55 0 55 0 0 -70 0 -70 75 0 75 0 0 135 0 135
-222 0 -223 0 6 -367z"/>
<path d="M2650 5386 l0 -66 30 0 30 0 0 -250 0 -250 -30 0 -30 0 0 -70 0 -70
175 0 175 0 0 69 0 70 -32 3 -33 3 0 85 0 85 31 3 c22 2 40 -4 58 -20 24 -20
28 -33 35 -110 10 -103 30 -144 89 -176 101 -56 225 -32 262 50 12 26 20 65
20 101 l0 57 -35 0 c-32 0 -35 -3 -35 -29 0 -35 -22 -58 -38 -39 -5 7 -13 36
-17 63 -4 28 -14 62 -21 77 -14 28 -64 69 -99 83 -18 6 -15 9 20 26 52 24 68
37 94 79 23 39 26 85 10 145 -16 58 -42 90 -96 116 -46 23 -59 24 -305 27
l-258 3 0 -65z m400 -81 c37 -19 50 -43 50 -93 0 -33 -6 -45 -31 -67 -27 -22
-40 -25 -83 -23 l-51 3 -3 84 c-2 46 -1 90 2 98 7 18 80 17 116 -2z"/>
<path d="M5896 4421 c-9 -10 -21 -35 -27 -55 -7 -26 -16 -36 -29 -36 -25 0
-26 -16 -1 -23 19 -4 19 -7 0 -108 -10 -57 -19 -105 -19 -106 0 -2 8 -3 19 -3
14 0 20 10 25 48 3 26 11 75 17 110 l11 62 50 0 50 0 -8 -52 c-5 -29 -9 -78
-9 -108 0 -55 0 -55 33 -58 35 -4 44 12 12 21 -23 6 -24 21 -7 122 11 60 15
70 35 73 28 4 29 18 2 25 -17 4 -20 10 -14 32 6 23 4 26 -12 23 -10 -2 -21
-14 -24 -28 -5 -22 -12 -25 -53 -28 -44 -3 -47 -1 -47 21 0 29 29 57 58 57 13
0 22 6 22 15 0 22 -63 20 -84 -4z"/>
<path d="M6870 4375 c-11 -58 -14 -64 -30 -55 -31 17 -95 12 -113 -8 -25 -28
-47 -88 -47 -130 0 -56 31 -92 79 -92 20 0 43 6 50 13 11 10 14 10 18 0 5 -13
33 -18 33 -7 0 13 51 310 56 327 4 12 0 17 -14 17 -16 0 -22 -11 -32 -65z
m-36 -81 c19 -18 20 -36 5 -104 -11 -51 -37 -80 -71 -80 -32 0 -48 29 -48 85
0 43 5 60 26 84 29 34 63 40 88 15z"/>
<path d="M7574 4265 c-28 -164 -28 -175 -12 -175 10 0 18 1 18 3 0 1 14 79 30
172 16 94 30 171 30 173 0 1 -8 2 -18 2 -16 0 -22 -23 -48 -175z"/>
<path d="M8056 4413 c-3 -16 -15 -84 -26 -153 -12 -69 -24 -135 -27 -147 -4
-18 -1 -23 15 -23 11 0 22 6 25 13 4 10 7 10 18 0 23 -22 93 -16 117 10 39 42
54 130 30 182 -16 36 -85 51 -112 24 -19 -18 -23 -8 -15 34 4 23 8 52 9 65 0
32 -27 28 -34 -5z m102 -112 c36 -22 31 -125 -8 -167 -28 -29 -61 -31 -85 -4
-17 18 -17 27 -7 83 15 80 56 117 100 88z"/>
<path d="M8286 4278 c-31 -189 -31 -188 -12 -188 15 0 23 31 51 200 9 52 18
107 21 123 5 22 2 27 -14 27 -17 0 -22 -18 -46 -162z"/>
<path d="M4110 4413 c0 -5 -11 -73 -25 -153 -29 -170 -29 -170 -11 -170 9 0
18 24 27 73 l14 72 68 3 c83 4 89 22 8 22 -33 0 -63 5 -66 10 -4 6 -1 35 5 65
l11 55 80 0 c64 0 79 3 79 15 0 12 -17 15 -95 15 -52 0 -95 -3 -95 -7z"/>
<path d="M5181 4391 c-33 -31 -40 -66 -20 -102 6 -11 39 -33 75 -49 58 -26 64
-32 64 -59 0 -76 -115 -98 -148 -28 -14 29 -42 37 -42 11 0 -8 13 -29 29 -45
24 -24 38 -29 78 -29 62 0 109 28 118 70 10 44 -16 77 -80 106 -68 30 -85 56
-58 93 35 46 133 35 133 -16 0 -7 7 -13 15 -13 24 0 17 39 -11 65 -40 37 -111
35 -153 -4z"/>
<path d="M5750 4399 c0 -24 23 -21 28 4 2 10 -3 17 -12 17 -10 0 -16 -9 -16
-21z"/>
<path d="M7066 4259 c-15 -88 -25 -163 -22 -166 15 -15 34 10 40 54 11 79 14
83 50 83 41 0 49 -9 72 -82 13 -43 22 -58 36 -58 23 0 23 2 -4 75 l-21 60 36
37 c49 48 51 94 6 132 -26 22 -40 26 -98 26 l-67 0 -28 -161z m165 121 c26
-15 26 -80 0 -104 -20 -19 -90 -32 -114 -23 -12 4 -13 16 -5 59 5 29 11 59 13
66 5 15 78 16 106 2z"/>
<path d="M7710 4400 c0 -15 5 -20 18 -18 9 2 17 10 17 18 0 8 -8 16 -17 18
-13 2 -18 -3 -18 -18z"/>
<path d="M4825 4375 c-3 -8 -7 -22 -10 -30 -2 -8 -14 -15 -25 -15 -11 0 -20
-4 -20 -10 0 -5 9 -10 21 -10 19 0 20 -4 12 -47 -4 -27 -8 -75 -8 -108 0 -63
12 -76 53 -60 15 6 14 8 -5 16 -27 10 -29 41 -10 134 11 50 17 61 35 63 28 4
28 18 1 25 -17 5 -20 11 -15 31 5 20 3 26 -9 26 -9 0 -18 -7 -20 -15z"/>
<path d="M7420 4332 c-57 -15 -100 -76 -100 -141 0 -38 23 -86 45 -95 50 -19
153 10 127 36 -7 7 -18 5 -33 -6 -47 -33 -99 -8 -99 47 l0 27 80 0 80 0 0 39
c0 61 -49 107 -100 93z m54 -44 c33 -46 22 -58 -49 -58 -36 0 -65 4 -65 9 0 5
12 23 26 40 31 37 66 40 88 9z"/>
<path d="M7856 4330 c-30 -9 -66 -37 -66 -52 0 -15 30 -8 50 12 38 38 95 18
88 -32 -3 -20 -11 -24 -56 -29 -75 -10 -102 -30 -102 -79 0 -29 5 -42 19 -50
27 -14 84 -13 100 3 11 10 14 10 18 0 3 -7 13 -13 24 -13 15 0 19 7 19 39 0
21 4 61 10 90 12 65 1 95 -41 110 -17 6 -32 11 -33 10 -1 0 -14 -5 -30 -9z
m64 -144 c0 -52 -71 -94 -104 -61 -36 37 0 85 64 85 36 0 40 -3 40 -24z"/>
<path d="M8470 4333 c-59 -19 -100 -77 -100 -142 0 -38 23 -86 45 -95 30 -11
90 -6 113 10 32 22 17 37 -18 19 -16 -8 -38 -15 -48 -15 -26 0 -52 31 -52 63
l0 27 80 0 80 0 0 35 c0 49 -18 82 -53 95 -16 5 -30 10 -31 9 -1 0 -8 -3 -16
-6z m54 -45 c32 -45 22 -58 -44 -58 -68 0 -78 15 -38 57 27 29 62 29 82 1z"/>
<path d="M4346 4309 c-27 -21 -34 -39 -15 -39 6 0 22 9 36 20 47 36 98 18 91
-32 -3 -20 -10 -24 -62 -29 -39 -5 -65 -13 -79 -27 -23 -24 -27 -73 -7 -96 15
-19 72 -21 103 -5 16 9 22 9 25 0 2 -6 9 -11 16 -11 9 0 17 33 26 100 12 93
12 101 -5 120 -25 28 -93 27 -129 -1z m100 -131 c-14 -73 -116 -92 -116 -22 0
31 36 54 83 54 39 0 39 0 33 -32z"/>
<path d="M4569 4309 c-14 -14 -19 -30 -17 -52 3 -28 9 -34 58 -54 41 -16 56
-27 58 -44 8 -50 -64 -65 -104 -22 -33 35 -54 18 -24 -20 18 -23 29 -27 69
-27 60 0 94 27 89 73 -2 23 -11 35 -30 44 -16 7 -40 19 -55 26 -33 15 -40 39
-18 61 22 22 58 20 80 -4 19 -21 45 -27 45 -10 0 6 -9 19 -20 30 -28 28 -102
28 -131 -1z"/>
<path d="M5384 4303 c3 -16 8 -62 11 -103 12 -142 25 -145 83 -18 23 50 45 88
49 84 4 -4 12 -44 18 -89 15 -118 29 -115 91 24 27 62 51 117 53 122 2 5 -5 7
-14 5 -12 -2 -31 -33 -55 -92 -20 -49 -39 -85 -42 -80 -3 5 -9 34 -12 64 -17
140 -28 143 -87 20 -46 -97 -59 -101 -59 -19 0 68 -10 109 -27 109 -11 0 -13
-8 -9 -27z"/>
<path d="M5714 4210 c-19 -111 -19 -120 -4 -120 14 0 20 20 34 103 10 56 20
110 22 120 4 12 0 17 -13 17 -16 0 -21 -18 -39 -120z"/>
<path d="M6256 4309 c-14 -11 -26 -25 -26 -30 0 -16 29 -10 50 11 38 38 95 18
88 -32 -3 -20 -11 -24 -56 -29 -75 -10 -102 -30 -102 -77 0 -21 5 -43 12 -50
16 -16 90 -15 107 1 11 10 14 10 18 0 3 -7 14 -13 24 -13 11 0 18 4 15 9 -3 4
-1 37 5 72 15 93 14 117 -6 139 -25 28 -93 27 -129 -1z m104 -120 c0 -32 -17
-55 -49 -68 -38 -16 -71 0 -71 34 0 31 35 55 82 55 32 0 38 -3 38 -21z"/>
<path d="M6470 4323 c0 -10 -27 -168 -36 -205 -5 -23 -2 -28 14 -28 16 0 21
13 32 87 15 92 39 133 77 133 46 -1 54 -27 37 -120 -17 -98 -17 -102 3 -98 13
2 21 21 30 73 16 96 16 131 -3 149 -18 19 -85 21 -103 4 -11 -10 -14 -10 -18
0 -5 13 -33 17 -33 5z"/>
<path d="M7677 4223 c-21 -131 -21 -133 -2 -133 8 0 15 1 15 3 0 2 9 52 20
112 24 132 24 125 2 125 -15 0 -21 -17 -35 -107z"/>
<path d="M4910 4113 c0 -10 -7 -31 -15 -47 -18 -35 -18 -36 0 -36 17 0 45 50
45 80 0 11 -7 20 -15 20 -8 0 -15 -8 -15 -17z"/>
<path d="M8617 4124 c-14 -15 -6 -34 14 -34 14 0 19 5 17 17 -3 18 -20 27 -31
17z"/>
<path d="M8727 4124 c-14 -15 -6 -34 14 -34 14 0 19 5 17 17 -3 18 -20 27 -31
17z"/>
<path d="M8827 4124 c-14 -15 -6 -34 14 -34 14 0 19 5 17 17 -3 18 -20 27 -31
17z"/>
</g>
</svg>

		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
